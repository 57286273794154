.container {
  padding: 1em;
  width: 50em;
  align-self: center; }

.text_container {
  display: flex;
  justify-content: space-around;
  margin: 1em 0; }

.radio_container {
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row !important;
  height: 2em;
  padding: 1em; }

.radio {
  color: var(--var-color-primary-yellow) !important; }

.title_container {
  display: flex;
  flex-direction: column;
  align-items: center; }

.call_to_action {
  display: flex;
  justify-content: space-between;
  margin: 2em 0; }

.url_youtube {
  margin: 2em 0; }

.expansion_panels {
  margin: 0.5em 0; }

.save_button {
  color: white !important;
  display: flex !important;
  padding: 0.5em 1.3em !important;
  background-color: #EFA817 !important;
  margin: 0 86.7% !important; }

.save_button:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important; }

.banner_button {
  display: flex;
  justify-content: flex-end; }

.add_banner_button {
  background-color: white !important;
  box-shadow: none !important;
  padding: 0.3em 1em !important;
  border: 1px solid !important; }
