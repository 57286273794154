.flex_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh; }

.page_background {
  background-color: var(--var-color-primary-grey); }

.icon {
  height: 12em; }

.text_color {
  color: var(--var-color-primary-black) !important; }

.home_button {
  color: var(--var-color-primary-white) !important;
  background: var(--var-color-primary-yellow) !important; }

.home_button_text {
  color: inherit;
  text-decoration: none; }
