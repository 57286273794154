.button-container {
  display: flex;
  justify-content: flex-end; }

.container-modal {
  width: 40%;
  margin-left: 29vw;
  margin-top: 25vh;
  background: #fff;
  padding: 1rem; }

.container-buttons {
  text-align: right; }
