.page-users {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.users__button {
  margin-top: 78px !important;
  margin-right: 20px !important;
  color: #FFFFFF !important;
  background-color: #EFA817 !important;
  float: right; }

.users__button:hover {
  background-color: #FFB319 !important; }

.users__modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.users__modal__paper {
  position: absolute;
  width: 400px;
  backgroundColor: #FFFFFF;
  boxShadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
  padding: 32px;
  outline: none, }

.users__table-delete {
  padding-right: 10px;
  cursor: pointer; }
.users__table-edit {
  cursor: pointer; }
