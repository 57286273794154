.page-reset {
  background-color: #efefee; }

.page-reset__container {
  max-width: 600px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 140px; }

.reset-card {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; }

.reset-title {
  font-weight: bold; }

.reset-subtitle {
  font-weight: bold; }

.reset-footer {
  padding: 25;
  font-weight: bold; }

.reset-input {
  width: 100%; }
