.page-login {
  background-color: #efefee; }

.page-login__container {
  max-width: 600px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.logo {
  display: block;
  margin: 0 auto;
  max-width: 7.7em !important;
  margin: 1.3em 0 !important; }

.login-card {
  width: 100%; }

.login-title {
  font-weight: bold; }

.login-input {
  margin: 0.5em !important;
  width: 100%; }

.login-forgot-password {
  color: #f1b231;
  display: inline-block;
  float: right;
  text-align: right;
  margin-right: 20px 50 5px 50; }

.logo-sundevs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.4em 0; }

.sundevs {
  width: 3em; }
