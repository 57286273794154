* {
  font-family: 'Poppins', sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: Poppins, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'corporatecondensedbold';
  src: url('./assets/fonts/corporatecondensedbold.woff2') format('woff2'),
    url('./assets/fonts/corporatecondensedbold.woff') format('woff'),
    url('./assets/fonts/corporatecondensedbold.ttf') format('truetype');
}
@font-face {
  font-family: 'corporatemonocondensed';
  src: url('./assets/fonts/corporatemonocondensed.woff2') format('woff2'),
    url('./assets/fonts/corporatemonocondensed.woff') format('woff'),
    url('./assets/fonts/corporatemonocondensed.ttf') format('truetype');
}
@font-face {
  font-family: 'IDAutomationHC39MCode39Barcode';
  src: url('./assets/fonts/IDAutomationHC39MCode39Barcode.woff2')
      format('woff2'),
    url('./assets/fonts/IDAutomationHC39MCode39Barcode.woff') format('woff'),
    url('./assets/fonts/IDAutomationHC39MCode39Barcode.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
}

:root {
  --var-color-primary-black: #000000;
  --var-color-primary-grey: #efefee;
  --var-color-primary-yellow: #efa817;
  --var-color-primary-white: #ffffff;
  --var-color-primary-blue-text: #455a64;
  --var-color-primary-red: #b5121b;
}
iframe {
  display: none;
}
