.button-container {
  display: flex;
  justify-content: flex-end; }

.expansion_body {
  display: flex;
  flex-direction: column; }

.banner_title {
  padding: 1em; }
