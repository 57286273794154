.page-kiosk {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.charts-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.page_header_container {
  padding: 15px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.page_header_container__left_content {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row; }

.config__button {
  color: #FFFFFF !important;
  background-color: #EFA817 !important; }
