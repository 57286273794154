.page-dashboard {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.rows_container {
  display: flex;
  flex-direction: row;
  width: 100%; }

.row {
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.rows_spacer {
  width: 100%; }
