.page-status {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.page_container {
  padding-top: 20px;
  height: 70px; }

.switch_city_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: #ffffff; }

.kiosks-status {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-column-gap: 10px;
  margin: 20px 10px 20px 20px; }

.text-total {
  color: #7c9efft; }

.text-service {
  color: #74e077; }

.text-without-service {
  color: #ff6e6a; }

.text-warning {
  color: #f69c21; }

.list-kiosks {
  background-color: #ffffff;
  margin: 20px; }
