.customDialogContainer {
  z-index: 10000;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: -webkit-fill-available; }

.paper {
  width: 20vw;
  position: absolute;
  top: 10%;
  left: 29%;
  padding: 1em; }

.countryContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.countryImage {
  width: 30%;
  border-radius: 50%; }

.countryName {
  font-weight: 600; }

.countryLabel {
  margin: 1em 0 0 0;
  color: #EFA817; }

.country {
  padding: 0.5em;
  padding-left: 0; }
