.page-settings {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.page-language__container {
  background-color: #fff; }

.page-language__form-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center; }
