.page-update {
  background-color: #efefee; }

.page-update__container {
  max-width: 600px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 140px; }

.update-card {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; }

.update-title {
  font-weight: bold; }

.update-subtitle {
  font-weight: bold; }

.update-footer {
  padding: 25;
  font-weight: bold; }

.update-input {
  width: 100%; }
