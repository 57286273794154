.page-companyChoose {
  background-color: var(--var-color-primary-grey);
  height: 100vh; }
.page-companyChoose__header {
  top: 0;
  margin-top: 0;
  position: sticky; }
.page-companyChoose__container {
  margin-top: 50px;
  text-align: center;
  font-size: 1em; }

