.page-dashboard {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.page-dashboard__grid-item {
  background-color: #fff;
  box-shadow: 1px 1px 10px 3px #ccc; }

.page-dashboard__country-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px; }

.page-dashboard__card-header {
  display: grid;
  grid-template-columns: 86% 14%;
  font-size: 13px;
  margin: 0 10px; }

.page-dashboard__card-title {
  font-size: 11px;
  display: grid;
  grid-template-columns: 55% 23% 22%;
  color: #6f6f6f;
  margin: 4px 10px 10px 10px; }

.page-dashboard__card-list {
  color: #434343; }

.page-dashboard__card-list-item {
  display: grid;
  grid-template-columns: 10% 76% 14%;
  align-items: center;
  font-size: 11px;
  margin: 0 10px 5px 10px; }

.page-dashboard__card-footer {
  display: grid;
  grid-template-columns: 100%; }

.page-dashboard__card-footer button {
  background-color: #f4f5f6;
  border: 0px;
  height: 30px;
  color: #626262;
  font-weight: bold; }

.page-dashboard__middle-button {
  background-color: #e9e9e9 !important; }

.page-dashboard__flag-icon {
  height: 50px;
  width: 50px;
  border-radius: 8px; }
.icon_button {
  padding: 0.1em !important;
  margin: 0 0 0 1.6em !important; }
