.form-input-subtitle {
  display: block; }

.form-group {
  margin-bottom: 15px; }

.form-button-container {
  display: flex;
  justify-content: flex-end; }

.formats__table-edit {
  padding-left: 20px; }
