.kiosk-status-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 2px 3px 5px 1px #ccc; }

.kiosk-status__card-icon img {
  border-radius: 50%; }

.kiosk-status__card-title span {
  display: block; }

.kiosk-status__card-number {
  font-size: 40px;
  font-weight: bold;
  color: #3f3f3f; }
