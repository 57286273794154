.add_location {
  background-color: #efa817 !important;
  color: white !important;
  padding: 0.5em 1em !important;
  margin: 0.2em 0 !important;
  border-radius: 4px !important; }

.dialog_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px !important; }

.button_close {
  top: 8px;
  color: #9e9e9e;
  right: 8px;
  position: absolute !important; }

.form_container {
  display: flex;
  justify-content: space-between;
  margin: 1em 0; }

TextField {
  margin: 0.3em !important; }
