.container-grid {
  display: grid; }

.row-container {
  grid-auto-flow: column; }

.component-container {
  grid-auto-flow: column;
  grid-template-columns: 100%;
  padding: 1px 0; }

.component-rows-container {
  grid-auto-flow: row; }

.column-container {
  grid-auto-flow: column;
  grid-template-columns: 100%; }

.column-item {
  margin: 0 1px;
  display: flex; }
