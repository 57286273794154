.page-kiosk {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.kiosk-info {
  margin: 20px; }

.page_header_container {
  padding: 15px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.page_header_container__left_content {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row; }

.config__button {
  color: #FFFFFF !important;
  background-color: #EFA817 !important; }

.delete__button {
  margin: 10px !important;
  color: #FFFFFF !important;
  background-color: #3f3f3f !important; }

.kiosk_detail_container {
  display: flex;
  flex-direction: row; }

.kiosk_detail_left_side {
  width: 70%; }

.kiosk_detail_row {
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.form-input-subtitle {
  display: block; }

.form-group {
  margin-bottom: 15px; }

.form-button-container {
  display: flex;
  justify-content: flex-end; }
