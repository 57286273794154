.dialogContent {
  padding: 1em !important;
  overflow-y: hidden !important; }

.locationsContainer {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 11em;
  border-top: 1px solid;
  margin: 0.2em 0; }

.lengthContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.inputsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.iputs {
  width: 48%; }
