.page-new-user {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.new_user__title-form {
  border-bottom: 1px solid #EFF1F1;
  padding: 10px 10px 0px 10px; }

.new_user__title-list {
  padding: 10px 10px 0px 10px; }

.new_user__content-form {
  padding: 16px; }

.header__toolbar {
  flex-grow: 1; }

.MuiSwitch-colorPrimary {
  color: #EFA91B !important; }

.role__button {
  margin: 1rem 0.5rem !important;
  color: #FFFFFF !important;
  background-color: #EFA817 !important; }

.role__button:hover {
  background-color: #FFB319 !important; }

.role__button:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important; }

.role__button_cancel {
  margin: 1rem 0.5rem !important;
  color: #EFA817 !important; }

.role__button_cancel:hover {
  color: #FFB319 !important;
  border: 1px solid #FFB319;
  background: #fff !important; }

.role__container_buttons {
  text-align: right; }


