.page-language__container {
  background-color: #fff;
  padding-bottom: 10px; }

.page-language__find-field {
  width: 97.5%;
  font-size: 14px;
  background-color: #F7F7F7;
  margin: 10px 0 0 0;
  border: 0;
  color: '#fff';
  padding: 10px; }

.page-language__form-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center; }
