.page-new-user {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.new_user__title-form {
  border-bottom: 1px solid #EFF1F1;
  padding: 10px 10px 0px 10px; }

.new_user__title-list {
  padding: 10px 10px 0px 10px; }

.new_user__content-form {
  padding: 16px;
  padding-bottom: 50px !important; }

.header__toolbar {
  flex-grow: 1; }

.New-user-save-button {
  color: #FFFFFF;
  float: right;
  margin-top: -50px !important;
  margin-right: 8px !important;
  background-color: #EFA817 !important; }

.New-user-back-button {
  color: #EFA817 !important;
  float: right;
  margin-top: -50px !important;
  margin-right: 130px !important;
  background-color: #EFA817; }
