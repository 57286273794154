
.page-transactions {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.find-field {
  width: 98%;
  font-size: 14px;
  background-color: #efa817;
  margin: 10px 0 0 0;
  border: 0;
  color: #fff;
  padding: 10px; }
