.page-format {
  background-color: #fff;
  min-height: 0vh;
  height: 100%; }

.page-language__container {
  background-color: #fff; }

.page-language__form-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center; }

.description_length_checker {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  margin: 5px;
  color: gray; }

.container-grid {
  display: grid; }

.main-container {
  grid-template-columns: 25% 35%;
  grid-column-gap: 40px; }

.main-container-next-tab {
  grid-template-columns: 35% 65%; }

.row-container {
  grid-auto-flow: column; }

.component-container {
  grid-auto-flow: column;
  grid-template-columns: 99% 1%;
  padding: 1px 0; }

.component-rows-container {
  grid-auto-flow: row; }

.column-container {
  grid-auto-flow: column;
  grid-template-columns: 95% 5%; }

.column-item {
  margin: 0 1px;
  display: flex; }

.main-container-item {
  margin: 0 0;
  box-shadow: none; }
