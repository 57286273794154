.container {
  padding: 1em; }
.field {
  width: 98%; }
.settings_container {
  display: grid;
  grid-template-columns: 50% 50%; }
.save_country_settings {
  left: 22.1em;
  width: fit-content;
  height: fit-content;
  position: relative;
  top: 2em;
  color: var(--var-color-primary-yellow) !important; }
